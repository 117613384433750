import { Component } from 'react';
import PropTypes from 'prop-types';
import { Route, Switch } from 'react-router-dom';
import { TextButton } from '@onc/composite-components';
import { OpenInNew } from '@onc/icons';
import withSnackbars from 'library/CompositeComponents/snackbars/withSnackbars';

import PamguardPSFUploadPage from './PamguardPSFUploadPage';
import PamguardTaskCreatePage from './PamguardTaskCreatePage';

class PamguardLandingPage extends Component {
  static propTypes = {
    match: PropTypes.shape({
      path: PropTypes.string.isRequired,
    }).isRequired,
  };

  renderPamguardPSFUploadPage = (routerProps) => (
    <PamguardPSFUploadPage {...routerProps} />
  );

  renderPamguardTaskCreatePage = (routerProps) => (
    <PamguardTaskCreatePage {...routerProps} />
  );

  renderPamguardLandingPage = (routerProps) => {
    const { match } = this.props;
    return (
      <>
        <TextButton
          target="_blank"
          href={`${match.path}/PamguardPSFUploadPage`}
          endIcon={<OpenInNew />}
        >
          Upload PSF
        </TextButton>
        <TextButton
          target="_blank"
          href={`${match.path}/PamguardAnnotationPage`}
          endIcon={<OpenInNew />}
        >
          Annotations
        </TextButton>
        <PamguardPSFUploadPage {...routerProps} />
      </>
    );
  };

  render() {
    const { match } = this.props;

    return (
      <Switch>
        <Route
          exact
          path={match.path}
          render={this.renderPamguardLandingPage}
        />
        <Route
          path={`${match.path}/PamguardPSFUploadPage`}
          render={this.renderPamguardPSFUploadPage}
        />
        <Route
          path={`${match.path}/PamguardTaskCreatePage`}
          render={this.renderPamguardTaskCreatePage}
        />
        <Route
          path={`${match.path}/statistics/:someId`}
          render={this.renderPamguardPSFUploadPage}
        />
      </Switch>
    );
  }
}

export default withSnackbars(PamguardLandingPage);
